<template>
  <Page title="Rechnungen" color="info">
    <template slot="help">
      Die Rechnungen werden nach Nummer sortiert aufgelistet. Du kannst aber die
      Sortierung anpassen und nach einem Anlass oder einer Beschreibung
      suchen.<br />
      Um eine neue Rechnung zu erfassen verwendest du den grünen Knopf unten
      rechts.
    </template>
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab :to="{ name: 'InvoicePending' }"> pendent </v-tab>
        <v-tab :to="{ name: 'InvoiceSubmitted' }">eingereicht </v-tab>
        <v-tab :to="{ name: 'InvoiceArchived' }"> archiviert </v-tab>
        <v-tab :to="{ name: 'InvoiceEvents' }"> Anlässe </v-tab>
        <v-toolbar
          color="transparent"
          flat
          dense
          v-if="!$vuetify.breakpoint.xs"
        >
          <v-spacer />
          <v-text-field
            v-model="search"
            single-line
            dense
            hide-details
            label="Anlass/Beschreibung suchen"
            append-icon="mdi-magnify"
            clearable
          />
        </v-toolbar>
      </v-tabs>
    </template>
    <v-btn
      fixed
      fab
      right
      bottom
      color="success"
      :to="{ name: 'InvoiceNew', params: { id: 0 } }"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
    <router-view :search="search"></router-view>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      search: '',
    };
  },
};
</script>
